import type { ComponentType } from "react";
import type { SiteId } from "@/contexts/site/models/site.types";
import AdvokatWatchLogo from "./components/AdvokatWatchLogo.component";
import AgriWatchLogo from "./components/AgriWatchLogo.component";
import AmWatchLogo from "./components/AmWatchLogo.component";
import ByrummonitorLogo from "./components/ByrummonitorLogo.component";
import CleantechWatchLogo from "./components/CleantechWatchLogo.component";
import DetailWatchLogo from "./components/DetailWatchLogo.component";
import EiendomsWatchLogo from "./components/EiendomsWatchLogo.component";
import EjendomsWatchLogo from "./components/EjendomsWatchLogo.component";
import EnergiWatchLogo from "./components/EnergiWatchLogo.component";
import EnergyWatchLogo from "./components/EnergyWatchLogo.component";
import FinansWatchLogo from "./components/FinansWatchLogo.component";
import FinanzBusinessLogo from "./components/FinanzBusinessLogo.component";
import FodevareWatchLogo from "./components/FodevareWatchLogo.component";
import HandelsWatchLogo from "./components/HandelsWatchLogo.component";
import ItWatchLogo from "./components/ItWatchLogo.component";
import KapitalWatchLogo from "./components/KapitalWatchLogo.component";
import KForumLogo from "./components/KForumLogo.component";
import type { LogoProps } from "./components/logo-props.types";
import MatvareWatchLogo from "./components/MatvareWatchLogo.component";
import MediaWatchLogo from "./components/MediaWatchLogo.component";
import MedWatchLogo from "./components/MedWatchLogo.component";
import MobilityWatchLogo from "./components/MobilityWatchLogo.component";
import PolicyWatchLogo from "./components/PolicyWatchLogo.component";
import ShippingWatchLogo from "./components/ShippingWatchLogo.component";
import UddannelsesmonitorLogo from "./components/UddannelsesmonitorLogo.component";
import WatchMediaLogo from "./components/WatchMediaLogo.component";
import WatchMedierLogo from "./components/WatchMedierLogo.component";
const siteLogosBySiteId: Readonly<Record<SiteId, ComponentType<LogoProps>>> = {
  "2022090": MedWatchLogo,
  "2022091": EnergiWatchLogo,
  "2022100": KForumLogo,
  "2023070": HandelsWatchLogo,
  "2024010": WatchMediaLogo,
  "2024020": MatvareWatchLogo,
  "2024030": FinansWatchLogo,
  "2024050": ByrummonitorLogo,
  "2024100": UddannelsesmonitorLogo,
  "advokatwatch.dk": AdvokatWatchLogo,
  "advokatwatch.no": AdvokatWatchLogo,
  "agriwatch.dk": AgriWatchLogo,
  "amwatch.dk": AmWatchLogo,
  "ctwatch.dk": CleantechWatchLogo,
  "detailwatch.dk": DetailWatchLogo,
  "eiendomswatch.no": EiendomsWatchLogo,
  "ejendomswatch.dk": EjendomsWatchLogo,
  "energiwatch.dk": EnergiWatchLogo,
  "energywatch.eu": EnergyWatchLogo,
  "finanswatch.dk": FinansWatchLogo,
  "finanswatch.no": FinansWatchLogo,
  "finanzbusiness.de": FinanzBusinessLogo,
  "fodevarewatch.dk": FodevareWatchLogo,
  "itwatch.dk": ItWatchLogo,
  "kapwatch.dk": KapitalWatchLogo,
  "mediawatch.dk": MediaWatchLogo,
  "medwatch.com": MedWatchLogo,
  "medwatch.dk": MedWatchLogo,
  "mobilitywatch.dk": MobilityWatchLogo,
  "policywatch.dk": PolicyWatchLogo,
  "shippingwatch.com": ShippingWatchLogo,
  "shippingwatch.dk": ShippingWatchLogo,
  "watchmedier.dk": WatchMedierLogo
};
type SiteLogoProps = LogoProps & {
  readonly siteId: SiteId;
};
export function SiteLogo({
  siteId,
  className,
  contextId,
  primaryClassName,
  secondaryClassName,
  size
}: SiteLogoProps) {
  const Logo = siteLogosBySiteId[siteId];
  return <Logo className={className} contextId={contextId} primaryClassName={primaryClassName} secondaryClassName={secondaryClassName} size={size} data-sentry-element="Logo" data-sentry-component="SiteLogo" data-sentry-source-file="SiteLogo.component.tsx" />;
}