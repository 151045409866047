"use client";

import type { Dispatch, SetStateAction } from "react";
import { ToolbarMenuButtonLatestNews } from "./components/ToolbarMenuButtonLatestNews.component";
import { ToolbarMenuButtonSearch } from "./components/ToolbarMenuButtonSearch.component";
import { ToolbarMenuButtonToggleMenu } from "./components/ToolbarMenuButtonToggleMenu.component";
import { ToolbarMenuList } from "./components/ToolbarMenuList.component";
import { ToolbarMenuListItem } from "./components/ToolbarMenuListItem.component";
type ToolbarMenuDenseProps = {
  readonly className?: string;
  readonly hasLatestNews: boolean;
  readonly isNavOpen: boolean;
  readonly onToggle: Dispatch<SetStateAction<boolean>>;
  readonly theme?: "light" | "dark";
};
export function ToolbarMenuDense({
  className,
  hasLatestNews = true,
  isNavOpen = false,
  theme = "dark",
  onToggle
}: ToolbarMenuDenseProps) {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This is a constant value.
  const strokeWidth = theme === "dark" ? 1 : 2;
  return <nav className={className} data-sentry-component="ToolbarMenuDense" data-sentry-source-file="ToolbarMenuDense.component.tsx">
      <ToolbarMenuList data-sentry-element="ToolbarMenuList" data-sentry-source-file="ToolbarMenuDense.component.tsx">
        {hasLatestNews ? <ToolbarMenuListItem theme={theme}>
            <ToolbarMenuButtonLatestNews strokeWidth={strokeWidth} />
          </ToolbarMenuListItem> : null}
        <ToolbarMenuListItem theme={theme} data-sentry-element="ToolbarMenuListItem" data-sentry-source-file="ToolbarMenuDense.component.tsx">
          <ToolbarMenuButtonSearch strokeWidth={strokeWidth} data-sentry-element="ToolbarMenuButtonSearch" data-sentry-source-file="ToolbarMenuDense.component.tsx" />
        </ToolbarMenuListItem>
        <ToolbarMenuListItem theme={theme} data-sentry-element="ToolbarMenuListItem" data-sentry-source-file="ToolbarMenuDense.component.tsx">
          <ToolbarMenuButtonToggleMenu isNavOpen={isNavOpen} strokeWidth={strokeWidth} onToggle={onToggle} data-sentry-element="ToolbarMenuButtonToggleMenu" data-sentry-source-file="ToolbarMenuDense.component.tsx" />
        </ToolbarMenuListItem>
      </ToolbarMenuList>
    </nav>;
}