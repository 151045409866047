"use client";

import React from "react";
import { RegularButton } from "@/components/Button/RegularButton/RegularButton.component";
import { OutlineChevronRightIcon } from "@/components/Icons/components/HeroIcons/OutlineChevronRightIcon.component";
import { LoginButton } from "@/components/Login/LoginButton/LoginButton.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useUser } from "@/contexts/user/user.context";
import { BuyButton } from "../../BuyButton/BuyButton.component";
import { UserLabel } from "../../UserLabel/UserLabel.component";
type ManageAccountWrapperProps = {
  readonly children?: React.ReactNode;
};
export default function ManageAccountWrapper({
  children
}: ManageAccountWrapperProps) {
  const {
    isLoggedIn,
    urls,
    model
  } = useUser();
  return <>
      <div>
        <ul>
          {isLoggedIn ? <li className="flex min-h-6 items-center border-b border-whisper transition-colors">
              <div className="container">
                {model.access.$class !== "ip" && model.access.$class !== "subscription" ? <BuyButton className="mb-1 mt-3 w-full" /> : null}
                <a className="flex h-full w-full items-center text-sm" href={urls.manageAccount}>
                  <div className="relative flex w-full flex-col gap-0.5 py-2">
                    <p className="font-bold uppercase">
                      <Translation da="Min konto" de="Mein Konto" en="My account" no="Min konto" sv="Mitt konto" />
                    </p>
                    <div className="break-normal pr-4 text-nobel">
                      <UserLabel />
                    </div>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <OutlineChevronRightIcon className="h-3 w-3" strokeWidth={2} />
                    </div>
                  </div>
                </a>
              </div>
            </li> : <li className="flex min-h-6 items-center border-b border-whisper transition-colors">
              <div className="container my-2 flex w-full flex-col justify-center">
                {model.access.$class !== "ip" && model.access.$class !== "subscription" ? <BuyButton className="mb-1" /> : null}

                <LoginButton className="w-full" variant="outline">
                  <Translation da="Log ind" de="Anmelden" en="Log in" no="Logg inn" sv="Logga in" />
                </LoginButton>
              </div>
            </li>}
        </ul>
        {children}
      </div>

      {isLoggedIn ? <div className="flex min-h-6 items-center border-whisper transition-colors">
          <div className="container my-2 flex w-full justify-center">
            <a className="w-full" href={urls.logoutAndReturnUrl}>
              <RegularButton className="w-full" variant="outline">
                <Translation da="Log ud" de="Abmelden" en="Log out" no="Logg ut" sv="Logga ut" />
              </RegularButton>
            </a>
          </div>
        </div> : null}
    </>;
}